/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { container } from '@stores/config';
import { Localisation, User, BillingAddress } from '@stores/domains';
import { TYPES } from '@stores/types';

export type Store = {
  user: User;
  billingAddress: BillingAddress;
  localisation: Localisation;
};

export const createStore = (): Store => {
  const localisation = container.get<Localisation>(TYPES.Localisation);
  const user = container.get<User>(TYPES.User);
  const billingAddress = container.get<BillingAddress>(TYPES.BillingAddress);

  return {
    localisation,
    billingAddress,
    user,
  };
};
