/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import React, { FunctionComponent, lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { paymentDetailsPath } from '@common/constants';
import { AuthHandler } from '@components/AuthHandler';
import { LocaleWrapper } from '@components/LocaleWrapper';
import { ProtectedDataRoute as ProtectedLayout } from '@components/ProtectedDataRoute';
import { I18n } from '@i18n/i18n';

const Error = lazy(() => import('@views/Error'));
const PaymentMethods = lazy(() => import('@views/PaymentMethods'));
const PaymentDetails = lazy(() => import('@views/PaymentDetails'));
const Receipt = lazy(() => import('@views/Receipt'));

interface AppRouterProps {
  i18nInstance: I18n;
}

export const AppRouter: FunctionComponent<AppRouterProps> = ({ i18nInstance }) => {
  const router = createBrowserRouter([
    {
      path: '/:lang?',
      element: <LocaleWrapper i18n={i18nInstance} />,
      children: [
        {
          path: 'oauth2/code',
          element: <AuthHandler />,
        },
        {
          element: <ProtectedLayout />,
          children: [
            {
              path: 'order/:orderId',
              element: <PaymentMethods />,
            },
            {
              path: `order/:orderId${paymentDetailsPath}`,
              element: <PaymentDetails />,
            },
            {
              path: 'receipt/:paymentId?',
              element: <Receipt />,
            },
            {
              index: true,
              path: '*',
              element: <Error />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
