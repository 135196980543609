/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { Container } from 'inversify';
import { Localisation, User, BillingAddress } from '@stores/domains';
import { TYPES } from '@stores/types';

export const container = new Container({ defaultScope: 'Singleton' });

container.bind<Localisation>(TYPES.Localisation).to(Localisation);
container.bind<User>(TYPES.User).to(User);
container.bind<BillingAddress>(TYPES.BillingAddress).to(BillingAddress);
